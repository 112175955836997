<template>
  <div class="page">
    <ls-title-header @back="cancel" title="新增用户" ></ls-title-header>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm" >
    <div style="background-color: white;">
      <div class="box">
        <el-row class="row">
          <el-col :span="12">
              <el-form-item prop="name">
                <template #label><span class="title">姓&emsp;&emsp;名</span></template>
                <el-input v-model="ruleForm.name" placeholder="请输入姓名" maxlength="10" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>

          <el-col :span="12">
              <el-form-item prop="idcard">
                <template #label><span class="title">身份证号</span></template>
                <el-input v-model="ruleForm.idcard" @input="idcardChange" placeholder="请输入身份证号" maxlength="18" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">性&emsp;&emsp;别</span></template>
                <el-input v-model="sSex" placeholder="请输入身份证" class="input" disabled></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">年&emsp;&emsp;龄</span></template>
                <el-input v-model="ruleForm.age" placeholder="请输入身份证" class="input" disabled></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">民&emsp;&emsp;族</span></template>
                <el-select v-model="ruleForm.nation" placeholder="请选择民族" class="input" >
                  <el-option v-for="item in nationoption" :key="item.name" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">出生年月</span></template>
                <el-input v-model="ruleForm.birthday" placeholder="请输入身份证" class="input" disabled></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">电&ensp;话&ensp;一</span></template>
                <el-input v-model="ruleForm.tel1" placeholder="请输入电话一" maxlength="15" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">电&ensp;话&ensp;二</span></template>
                <el-input v-model="ruleForm.tel2" placeholder="请输入电话二" maxlength="15" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">居住地址</span></template>
                <ls-address @adr-change="getAddress" class="input" ></ls-address>
              </el-form-item>
               <!-- <el-form-item prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入详细地址" class="input" style="margin-top:20px;"></el-input>
              </el-form-item> -->
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">身份证地址</span></template>
                <el-input v-model="ruleForm.idcardAddress" placeholder="请输入身份证地址" maxlength="100" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
               <el-form-item>
                <el-input v-model="this.address.detail" placeholder="请输入详细地址" maxlength="100" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item prop="mo">
                <template #label><span class="title">绑定账号</span></template>
                <el-input v-model="ruleForm.mo" placeholder="请输入手机号码,用于登录APP" class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <hr />
        <el-row class="row">
          <el-col :span="12">
               <el-form-item>
                <template #label><span class="title">第一联系人</span></template>
                <el-input v-model="ruleForm.oneContactMan" maxlength="10" show-word-limit placeholder="请输入第一联系人" class="input"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">第二联系人</span></template>
                <el-input v-model="ruleForm.twoContactMan" maxlength="10" show-word-limit placeholder="请输入第二联系人" class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>  
        <el-row class="row">
          <el-col :span="12">
               <el-form-item>
                <template #label><span class="title">联系方式</span></template>
                <el-input v-model="ruleForm.oneContactPhone" maxlength="15" show-word-limit placeholder="请输入联系方式" class="input"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">联系方式</span></template>
                <el-input v-model="ruleForm.twoContactPhone" maxlength="15" show-word-limit placeholder="请输入联系方式" class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
               <el-form-item>
                <template #label><span class="title">亲属关系</span></template>
                <el-input v-model="ruleForm.oneRelation" maxlength="20" show-word-limit placeholder="请输入亲属关系" class="input"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item>
                <template #label><span class="title">亲属关系</span></template>
                <el-input v-model="ruleForm.twoRelation" maxlength="20" show-word-limit placeholder="请输入亲属关系" class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
            <div style="display:flex;align-items: center;">
              <el-button type="primary" style="float:right;" size="small" @click="submitForm('ruleForm')">提交</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    </el-form>
  </div>
</template>

<script>
import nation from "../../../until/nation";
import {addUserInfo} from '../../../api/api'
import session from '../../../store/store'
import idtiother from '../../../until/idcard'

export default {
  data() {
    return {
      ruleForm: {
        name: '',
        idcard:'',
        birthday:'',
        age:'',
        sex:'',
        nation:'',
        tel1:'',
        tel2:'',
        idcardAddress:'',
        mo:'',
        oneContactMan:'',
        oneContactPhone:'',
        oneRelation:'',
        twoContactMan:'',
        twoContactPhone:'',
        twoRelation:'',
      },
      sSex:'',
      nationoption:[],
      rules: {
        name: [
          {required: true,message: '请输入姓名', trigger: 'blur',}
        ],
        idcard: [
        { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        mo:[
          {required: false,min: 0,max: 11,message: "长度在 0 到 11 个数字", trigger: "blur"},
          {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: "请输入合法手机号", trigger: 'blur' }
        ],
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getNation();
    },
    getNation() {
      this.nationoption = nation.nationList();
    },
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
     /**
     * 身份证输入框改变时触发
     */
    idcardChange(v) {
      let idList = idtiother.go(v);
      this.ruleForm.birthday = idList.birth;
      this.ruleForm.age = idList.age;
      this.ruleForm.sex = idList.sex;
      if(this.ruleForm.sex == '1') {
        this.sSex = '男'
      }else {
        this.sSex = '女'
      }
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.addUser();
          
        } else {
          return false;
        }
      });
    },

    addUser() {
      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.ruleForm.address = '';
      }else {
        this.ruleForm.address = address;
      }
      this.ruleForm.uuid = session.getLoginInfo().unitUuid;
      this.ruleForm.ly = 2;

      addUserInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '新增成功'});
          this.cancel();
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })

    },

    cancel() {
      this.$emit('cancel')
    },
    close(){
      this.$emit("update")
    }
  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>