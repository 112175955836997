<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="用户列表"></ls-title>
    <div class="conddiv">
      <el-input v-model="condition"  placeholder="搜索姓名/身份证" prefix-icon="el-icon-search" style="width:200px;">
      </el-input>
      <el-select v-show="role!=1" v-model="groupUuid" style="margin-left:5px;" placeholder="分组搜索" size="large" clearable>
        <el-option v-for="item in groupOption" :key="item.uuid" :label="item.groupName" :value="item.uuid"></el-option>
      </el-select>
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      <el-button v-if="role!=1" type="warning" style="float:right;display: none;" @click="leadInPage=true">批量导入</el-button>
      <el-button v-if="role!=1" type="primary" style="float:right;" @click="addUser">添加用户</el-button>
    </div>

    <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="this.emptyText">
      <el-table-column type="index" label="序号" width="60" label-class-name="tablehead" align="center" />
      <el-table-column v-if="role==1" prop="unitName" label="机构名称" label-class-name="tablehead" align="center " />
      <el-table-column prop="name" width="120" label="姓名" label-class-name="tablehead" align="center " />
      <el-table-column prop="age" label="年龄" width="80" label-class-name="tablehead" align="center " />
      <el-table-column prop="sex" label="性别"  width="80" label-class-name="tablehead" align="center " >
        <template #default="scope">
          <span v-if="scope.row.sex == 1">男</span>
          <span v-if="scope.row.sex == 0">女</span>
        </template>
      </el-table-column>
      <el-table-column prop="idcard" label="身份证" width="180" label-class-name="tablehead" align="center " />
      <el-table-column prop="address" label="居住地址" label-class-name="tablehead" align="center " >
        <template #default="scope">
          {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
        </template>
      </el-table-column>
      <el-table-column prop="groupName" label="分组" width="180" label-class-name="tablehead" align="center " />
      <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
        <template #default="scope">
          <span class="btn" @click="detail(scope.row)">查看</span> 
          <span v-if="role!=1"> / </span>
          <span v-if="role!=1" class="btn" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div> 


    <!-- 批量导入 -->
    <!-- <ls-leading-in v-if="leadInPage" @close="leadInPage=false" @refresh="getUserList" title="批量导入" :action="uploadFile()" :downFileUrl="downFileUrl" :fileData="fileData"></ls-leading-in> -->

  </div>


  <template v-if="addPage">
    <UserAdd @update="update" @cancel="closeUser"></UserAdd>
  </template>


</template>

<script>
import {getUserInfos,deleteUserInfo,getUserGroupInfos } from '../../../api/api'
import session from "../../../store/store";
import UserAdd from './UserAdd.vue'
export default {
  components: {
    UserAdd,
  },
  data() {
    return {
      role:0,
      mainPage:true,
      addPage:false,
      leadInPage:false,
      emptyText:'加载中',
      condition: "",
      groupUuid:'',
      currentPage: 1,
      size: 10,
      total: 0,
      dataList: [],
      groupOption:[],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getUserInfos();
      this.getUserGroupInfos();
    },
    search() {
      this.getUserInfos();
    },
    update(){
      this.getUserInfos();
    },
    getUserInfos() {
      let param = {
        uuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
        groupUuid: this.groupUuid,
      }
      getUserInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    getUserGroupInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        page:0,
        size:1000,
      }
      getUserGroupInfos(param).then(res => {
        if (res.data.code == 200) {
          this.groupOption = res.data.dataInfo;
        }else {
          this.groupOption.splice(0, this.groupOption.length);
        }
      })
    },

    uploadFile() {
      // let hosturl = window.location.host;
      // var uploadPath =
      //   "http://" + hosturl + "/homeapi/homeapi/subsidy/addBatchSubsidyInfo";
      // return uploadPath;
    },
    /**
     *查看
     */
    detail(row) {
      this.row = row;
      session.setCustomerData(row);
      console.log(window.location.protocol)
      window.open(window.location.protocol + "//"+window.location.host+"/user-base")
    },
    /**
     * 删除
     */
    del(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            unitUuid:session.getLoginInfo().unitUuid,
            uuid:row.uuid,
          }
          deleteUserInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getUserInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })

        }).catch(() => {});
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUserInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserInfos();
    },
    addUser() {
      this.mainPage = false;
      this.addPage = true;
    },
    closeUser() {
      this.mainPage = true;
      this.addPage = false;
    },

  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.btn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>